
import { Organization, SearchAction, WebSite, WithContext } from 'schema-dts';
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

import { EContentType } from '@/domain/core/ContentType.enum';
import { ELinkType } from '@/domain/core/Meta.enum';
import { ERouteName } from '@/domain/core/Routes.enum';
import { ESchemaProperty, ESchemaType, SchemaDotOrgUrl } from '@/domain/core/Schema.enum';
import { ESocialUrl } from '@/domain/core/Social.enum';
import { EditoBlock } from '@/domain/edito/types';
import { alternateLinksConfig } from '@/head/meta/links/alternate';
import { createMetaTags } from '@/head/meta/tags';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';

export default Vue.extend({
  name: 'HomePage',
  async asyncData({ $services, error, $errorMonitor, req }): Promise<{ blocks?: EditoBlock[]; origin?: string } | void> {
    let origin = '';

    if (req?.headers?.host) {
      origin = `https://${req.headers.host}`;
    } else {
      $errorMonitor.report(`Could not find host from request headers "${JSON.stringify(req?.headers || {})}"`, 'error');
    }

    try {
      const blocks = await $services.editoBlocksDataService.getEditoBlocks() || [];

      return {
        blocks,
        origin,
      };
    } catch (err) {
      const { content, statusCode } = err as HttpResponse;

      $errorMonitor.report(content || err, 'fatal');
      error({ statusCode });
    }
  },
  data() {
    return {
      blocks: [] as EditoBlock[],
      origin: '',
    };
  },
  head(): MetaInfo {
    const title = this.$t('meta.default.title') as string;
    const alternateLinks = alternateLinksConfig.map(({ countryCode, hreflang }) => ({
      rel: ELinkType.Alternate,
      href: `https://${this.$config.domains[countryCode]}`,
      hid: `${ELinkType.Alternate}_${hreflang}`,
      hreflang,
    }));
    const canonicalLink = {
      rel: ELinkType.Canonical,
      href: `${this.origin}`,
      hid: ELinkType.Canonical,
    };

    return {
      title,
      link: [
        ...alternateLinks,
        canonicalLink,
      ],
      meta: createMetaTags({
        description: this.$t('meta.default.description') as string,
        title,
      }),
      script: [
        {
          body: true,
          json: this.webSiteStructuredData,
          type: EContentType.JsonLD,
        },
        {
          body: true,
          json: this.organizationStructuredData,
          type: EContentType.JsonLD,
        },
      ],
    };
  },
  computed: {
    isUserAuthenticated(): boolean {
      return this.$accessor.user.authenticated;
    },
    isUserPro(): boolean {
      return this.$accessor.user.isPro;
    },
    organizationStructuredData(): Record<string, any> {
      const organization: WithContext<Organization> = {
        [ESchemaProperty.Context]: SchemaDotOrgUrl,
        [ESchemaProperty.Type]: ESchemaType.Organization,
        name: 'Selency',
        url: this.origin,
        sameAs: Object.values(ESocialUrl),
        logo: 'https://assets.selency.co/img/main-logo.png',
      };

      return organization;
    },
    webSiteStructuredData(): Record<string, any> {
      // Source : https://github.com/google/schema-dts/issues/114
      type QueryAction = SearchAction & {
        'query-input': string;
      };

      const searchHref = this.$router.resolve({ name: ERouteName.Search }).href;
      const website: WithContext<WebSite> = {
        [ESchemaProperty.Context]: SchemaDotOrgUrl,
        [ESchemaProperty.Type]: ESchemaType.Website,
        url: this.origin,
        potentialAction: {
          [ESchemaProperty.Type]: ESchemaType.SearchAction,
          target: `${this.origin}${searchHref}?query={search_term_string}`, // NOTE: curly braces are encoded when passed to vue-router's query parameters. We need to avoid this.
          'query-input': 'required name=search_term_string',
        } as QueryAction,
      };

      return website;
    },
  },
  mounted() {
    if (this.$route.query.log === 'departmentcode') {
      this.$errorMonitor.report(`Department number found : "${this.$accessor.user.frenchDepartmentNumber}"`, 'info');
    }
  },
});
